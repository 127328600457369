import { template as template_af91df7f3ba74f4983d40848ffe23d7e } from "@ember/template-compiler";
const FKControlMenuContainer = template_af91df7f3ba74f4983d40848ffe23d7e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
