import { template as template_672a64014d9c4c7ba41eaa7dd8a57a47 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_672a64014d9c4c7ba41eaa7dd8a57a47(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
